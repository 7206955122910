
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import * as React from 'react';
import * as FirebaseUtils from 'utils/backend/sdk/firebase';
import * as TestySDK from 'utils/backend/sdk/testy-web-sdk';
import * as AuthUtils from 'utils/backend/sdk/auth';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ConfirmationResult } from 'firebase/auth';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from 'components/atomic/atoms/button';
import FormTextField from 'components/atomic/atoms/form-field-input';
import { LoginSchema, passwordSchema } from 'utils/form/validation-schema';
import { AuthContext } from 'components/providers/auth-provider';
import Routes from 'utils/routes';
import { useAppConfig } from 'components/hooks/features/use-features';
import RecoveryPasswordModal from 'components/atomic/molecules/recovery-password-modal';
import useTranslation from 'next-translate/useTranslation';
import LoginWelcomeMessage from 'components/atomic/molecules/login-welcome-message';
import LoginFormLayout from 'components/layout/login-form-layout';
import LinkButton from 'components/atomic/atoms/link-button';
import { analytics } from 'utils/analytics';
export default function LoginPage() {
    const { palette } = useTheme();
    const router = useRouter();
    const { appConfig } = useAppConfig();
    const { t } = useTranslation('common');
    const [loading, setLoading] = React.useState(false);
    const { setUser } = React.useContext(AuthContext);
    const [identifierVerified, setIdentifierVerified] = React.useState(false);
    const [identifierHelperText, setIdentifierHelperText] = React.useState('');
    const [phoneConfirmationResult, setPhoneConfirmationResult] = React.useState<ConfirmationResult | undefined>();
    const [phoneOTPCodeHelperText, setPhoneOTPCodeHelperText] = React.useState('');
    const [passwordHelperText, setPasswordHelperText] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [recoverPassModalShow, setRecoverPassModalShow] = React.useState(false);
    const validationSchema = React.useMemo(() => {
        if (!identifierVerified)
            return LoginSchema;
        else
            return passwordSchema;
    }, [identifierVerified]);
    const form = useFormik({
        initialValues: {
            identifier: '',
            password: '',
            phoneOTPCode: '',
            phone: '',
        },
        onSubmit: () => {
            onSubmit();
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });
    const isPhoneIdentifier = React.useMemo(() => {
        const reg = new RegExp('^[+][0-9]{2}[0-9]{10}$');
        const reg2 = new RegExp('^[0-9]{10}$');
        if (reg.test(form.values.identifier))
            return true;
        if (reg2.test(form.values.identifier))
            return true;
        else
            return false;
    }, [form.values.identifier]);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const verifyUserWithEmailExists = async () => {
        TestySDK.checkUserExistance({ email: form.values.identifier })
            .then(() => {
            setIdentifierVerified(true);
            setIdentifierHelperText('');
        })
            .catch((e) => {
            if (e.response) {
                if (e.response.status === 404) {
                    setIdentifierHelperText(t('errors.noUser'));
                }
                else
                    setIdentifierHelperText(e.message);
            }
            else {
                setIdentifierHelperText(e.message);
            }
        })
            .finally(() => setLoading(false));
    };
    const verifyUserWithPhoneExists = async () => {
        TestySDK.checkUserExistance({ phone_number: form.values.identifier })
            .then(() => {
            FirebaseUtils.signWithPhoneNumber(form.values.identifier).then((res) => {
                setIdentifierVerified(true);
                setPhoneConfirmationResult(res);
            });
        })
            .catch((e) => {
            if (e.response) {
                if (e.response.status === 404) {
                    setIdentifierHelperText('Nessun utente con questo numero di telefono');
                }
                else
                    setIdentifierHelperText(e.message);
            }
            else {
                setIdentifierHelperText(e.message);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const signInWithPhone = async () => {
        if (!phoneConfirmationResult)
            throw new Error('Phone confirmation result is undefined');
        FirebaseUtils.checkPhoneVerificationCode(form.values.phoneOTPCode, phoneConfirmationResult)
            .then(async (user) => {
            const idToken = await user.user.getIdToken();
            const token = await AuthUtils.exchangeFirebaseTokenWithCustom(idToken);
            const userBasicData = AuthUtils.userBasicDataParser(user.user, token);
            TestySDK.setAuthToken(userBasicData.token);
            setUser(userBasicData);
            router.push(Routes.Home);
        })
            .catch((e) => {
            setPhoneOTPCodeHelperText(e.message);
        })
            .finally(() => setLoading(false));
    };
    const signInWithCredentials = async (email: string, password: string) => {
        try {
            const user = await FirebaseUtils.signInWithEmailAndPassword(email, password);
            const idToken = await user.getIdToken(true);
            const token = await AuthUtils.exchangeFirebaseTokenWithCustom(idToken);
            const userBasicData = AuthUtils.userBasicDataParser(user, token);
            await analytics.identify(`${userBasicData.email}`, userBasicData);
            setUser(userBasicData);
            analytics.track('login_success');
            TestySDK.setAuthToken(userBasicData.token);
            router.push(Routes.Home);
        }
        catch (e) {
            analytics.track('login_error');
            setPasswordHelperText(t('errors.passwordIncorrect'));
            setLoading(false);
        }
    };
    const onSubmit = async () => {
        setLoading(true);
        if (!identifierVerified) {
            if (!isPhoneIdentifier)
                await verifyUserWithEmailExists();
            else
                await verifyUserWithPhoneExists();
            return;
        }
        if (!form.values.phoneOTPCode)
            await signInWithCredentials(form.values.identifier, form.values.password);
    };
    const handleErrorIdentifier = React.useMemo(() => {
        if (form.errors.identifier)
            return t(`${form.errors.identifier}`);
        if (identifierHelperText)
            return identifierHelperText;
        else
            return '';
    }, [form.errors.identifier, identifierHelperText, t]);
    const handleErrorPassword = React.useMemo(() => {
        if (form.errors.password)
            return t(`${form.errors.password}`);
        if (passwordHelperText)
            return passwordHelperText;
        else
            return '';
    }, [form.errors.password, passwordHelperText, t]);
    return (<LoginFormLayout>
            <Box>
                <Head>
                    <title>Accedi</title>
                </Head>
                <LoginWelcomeMessage />

                <div id="recaptcha-container"></div>

                <FormTextField value={form.values.identifier} helperText={handleErrorIdentifier} inputProps={{
            'data-testid': 'email',
        }} onChangeFn={form.handleChange('identifier')} placeholder={t('identifier')} disabled={identifierVerified} endAdornment={identifierVerified && (<InputAdornment position="end">
                                <CheckCircleRoundedIcon style={{ color: palette.primary.main }}/>
                            </InputAdornment>)}/>

                {/** Password field */}
                {!isPhoneIdentifier && identifierVerified ? (<FormTextField value={form.values.password} helperText={handleErrorPassword} inputProps={{
                'data-testid': 'password',
            }} onChangeFn={form.handleChange('password')} placeholder={t('insertPass')} type={showPassword ? 'text' : 'password'} password endAdornment={<InputAdornment position="end">
                                <IconButton onClick={handleClickShowPassword} onMouseDown={(e) => e.preventDefault()} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>}/>) : null}

                {/** Phone OTP field */}
                {isPhoneIdentifier && phoneConfirmationResult ? (<FormTextField value={form.values.phoneOTPCode} helperText={form.errors.phoneOTPCode ?? phoneOTPCodeHelperText} onChangeFn={form.handleChange('phoneOTPCode')} placeholder="Codice OTP"/>) : null}

                <LinkButton sx={{ mt: 1 }} onClick={() => setRecoverPassModalShow(true)} dataCy="change_mod_login">
                    <u> {t('passwordForgot')}</u>
                </LinkButton>

                <Button variant="contained" fullWidth sx={{ mt: 2 }} data-testid={'login-button'} disabled={!!form.errors.identifier && form.touched.identifier} loading={loading} onClick={form.values.phoneOTPCode ? () => signInWithPhone() : () => form.handleSubmit()}>
                    Continua
                </Button>
                {!appConfig.feature.includes('restrictedSignup') ? (<>
                        <Box display="flex" alignItems="center" mt={1} ml={1} gap={1}>
                            <Typography color={palette.font.secondary} variant="caption">
                                {t('noAccount')}
                            </Typography>
                            <LinkButton onClick={() => router.push(Routes.SignUp)} dataCy="change_mod_login">
                                {t('SignUp')}
                            </LinkButton>
                        </Box>
                    </>) : null}
                {recoverPassModalShow ? (<RecoveryPasswordModal recoverPassModalShow={recoverPassModalShow} setRecoverPassModalShow={setRecoverPassModalShow} identifier={form.values.identifier} setIdentifier={form.handleChange('identifier')}/>) : null}
            </Box>
        </LoginFormLayout>);
}

    async function __Next_Translate__getStaticProps__18a215a514c__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18a215a514c__ as getStaticProps }
  